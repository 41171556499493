@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  color: inherit;
}

body {
  font-family: "Open Sans", sans-serif !important;
  min-height: 100vh;
}

.font-inter {
  font-family: "Inter", sans-serif !important;
}

.site-layout {
  min-height: 93vh;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #254769;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-popover {
  min-width: 100px;
  width: 500px;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #fff !important;
}

form input,
textarea {
  border: none;
  background: hsl(0 0% 93%);
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  outline: 0;
  font-size: 14px;
}

/* .parentTable
  table
  tbody
  .ant-table-expanded-row.ant-table-expanded-row-level-1
  > td {
  padding: 0px !important;
}

.nestedTable > div > div > div {
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
} */

.btn {
  color: white;
  outline: 0;
  width: 100%;
  border: 0;
  padding: 15px;
  cursor: pointer;
}

.profile_tile {
  /* background-color: #f2f2f2; */
  font-size: 11px;
  padding: 25px 25px;
  border-radius: 25px;
  margin-top: 70px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.profile_tile span {
  display: block;
}

.profile_img {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background-color: #f2f2f2;
  margin-top: -60px;
  margin-left: auto;
  margin-right: auto;
  top: -20px;
}

h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}
